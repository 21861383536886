import { useParams } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ScrollToTop from "../ScrollToTop";
import incubatorData from "../data/incubators.json";
import incubatorData2 from "../data/incubatorsData2.json";

const IncubatorDetails = () => {
  const { title } = useParams();
  let arr = [...incubatorData, ...incubatorData2];
  const eachProduct = arr?.filter((item) => item.title === title)[0];
  return (
    <>
      <Header />
      <section className="about mt-16 h-[250px] sm:h-[450px] relative ">
        <div className="overlay slide2 lg:px-[15%] px-5 py-10 flex items-center">
          <div className="text-white font-light mx-auto">
            <h1 className="text-[1.75rem] sm:text-[2.6rem] uppercase">
              {eachProduct?.title}
            </h1>
            <div className="w-full h-[1px] bg-gradient-to-r from-white to-white/10"></div>
          </div>
        </div>
      </section>
      <section className="w-full min-h-[300px] lg:px-[15%] px-5 py-12 sm:py-20"></section>
      <div className="w-full bg-white px-2 md:px-5 py-4 border-t border-[#fec901] fixed bottom-0 left-0 flex items-center z-10">
        <div className="flex items-center gap-2 md:gap-4 mr-auto">
          <img
            alt=""
            src="/images/icons8-tools-50.png"
            className="w-8 h-8 md:w-12 md:h-12"
          />
          <p className="text-[.85rem] md:text-[1rem]">
            Incubators are being updated, stay tuned!
          </p>
        </div>
      </div>
      <ScrollToTop />
      <Footer id="footer" />
    </>
  );
};

export default IncubatorDetails;
