import Footer from "../components/Footer";
import Header from "../components/Header";
import IncubatorCard from "../components/IncubatorCard";
import ScrollToTop from "../ScrollToTop";
import incubatorData from "../data/incubators.json";
import incubatorData2 from "../data/incubatorsData2.json";

const Incubators = () => {
  return (
    <>
      <Header />
      <section className="about mt-16 h-[250px] sm:h-[450px] relative ">
        <div className="overlay slide3 lg:px-[15%] px-5 py-10 flex items-center">
          <div className="text-white font-light mx-auto">
            <h1 className="text-[1.75rem] sm:text-[2.6rem] uppercase">
              Incubators
            </h1>
            <div className="w-full h-[1px] bg-gradient-to-r from-white to-white/10"></div>
          </div>
        </div>
      </section>
      <section className="w-full min-h-[400px] lg:px-[12%] px-5 py-12 sm:py-20">
        <div className="w-full bg-black/30 mt-10 mb-8 p-5 gap-5 rounded-lg grid grid-flow-col place-items-center scroll-auto overflow-y-auto overscroll-x-contain snap-mandatory md:snap-none snap-x no-scrollbar">
          {incubatorData?.map((item, index) => {
            return <IncubatorCard key={index} item={item} />;
          })}
        </div>
        <div className="flex gap-3 items-center">
          <p>Swipe to view more incubators</p>
          <img
            alt=""
            src="/images/icons8-right-arrow-48.png"
            className="w-12 h-8"
          />
        </div>

        <div className="w-full bg-black/30 mt-20 mb-8 p-5 gap-5 rounded-lg grid grid-flow-col place-items-center scroll-auto overflow-y-auto overscroll-x-contain snap-mandatory md:snap-none snap-x no-scrollbar">
          {incubatorData2?.map((item, index) => {
            return <IncubatorCard key={index} item={item} />;
          })}
        </div>
        <div className="flex gap-3 items-center">
          <p>Swipe to view more incubators</p>
          <img
            alt=""
            src="/images/icons8-right-arrow-48.png"
            className="w-12 h-8"
          />
        </div>
      </section>
      <div className="w-full bg-white px-2 md:px-5 py-4 border-t border-[#fec901] fixed bottom-0 left-0 flex items-center z-10">
        <div className="flex items-center gap-2 md:gap-4 mr-auto">
          <img
            alt=""
            src="/images/icons8-tools-50.png"
            className="w-8 h-8 md:w-12 md:h-12"
          />
          <p className="text-[.85rem] md:text-[1rem]">
            More incubators are being updated, stay tuned!
          </p>
        </div>
      </div>
      <ScrollToTop />
      <Footer id="footer" />
    </>
  );
};

export default Incubators;
